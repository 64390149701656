import React, { useEffect } from 'react';
import './CustomText.css';

interface CustomTextProps {
  text: string;
}

const CustomText: React.FC<CustomTextProps> = ({ text }) => {
  const getHrefComponent = (splitText: string, index: number) => {
    let regExp = /\(([^)]+)\)/;
    let url = regExp.exec(splitText);
    let urlText = splitText.substring(0, splitText.indexOf('('));
    if (url) {
      let finalUrl = url[0];
      finalUrl = finalUrl.replace('(', '');
      finalUrl = finalUrl.replace(')', '');
      finalUrl = finalUrl.replaceAll('\\', '');
      return `<a href="${finalUrl}" target="_blank" style="text-decoration:none; color:#ff0a78;">${urlText}</a>`;
    }
    return ``;
  };

  const addInlineHighlight = () => {
    let highLightedText = text;
    const htmlStart = `<span style="background-color: #20262E;margin:0px; padding-left:8px; padding-right:8px; border-radius:8px";>`;
    const htmlEnd = `</span>`;
    highLightedText = highLightedText.replaceAll('QQ', htmlStart);
    highLightedText = highLightedText.replaceAll('qq', htmlEnd);
    return highLightedText;
  };

  const getCustomText = () => {
    let highLightedText = addInlineHighlight();
    const textArray: string[] = highLightedText.split('||');
    let textSpan: string = '';
    textArray.forEach((string, index) => {
      if (string.includes('http') || string.includes('mailto')) {
        textSpan = textSpan + getHrefComponent(string, index);
      } else {
        textSpan = textSpan + string;
      }
    });
    return '<p  style="padding:0px; margin:0px">' + textSpan + '</p>';
  };

  return (
    <div className="CustomTextContainer">
      <div
        className="CustomTextText"
        dangerouslySetInnerHTML={{ __html: getCustomText() }}
      />
    </div>
  );
};

export default CustomText;
