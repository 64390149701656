import React, { useRef, useState } from 'react';
import './NewsLetter.css';
import { TextField } from '@mui/material';
import { AwesomeButton } from 'react-awesome-button';
import 'react-awesome-button/dist/styles.css';
import Typewriter from '../Typewriter/Typewriter';
import { addToNewsLetter } from '../../utils/api';
import { DefaultApiResponseData } from '../../models/DefaultApiResponseData';
import { STATUS_OK } from '../../utils/constants';
import { ToastContainer, toast } from 'react-toastify';
import { isValidEmail, isValidName } from '../../utils/stringUtils';

const HEADING = 'Sign Up 🚀';
const SUB_HEADING = 'A Newsletter for ';
const TYPEWRITER_ARRAY = ['Developers', 'Enthusiasts', 'Tinkerers', 'Coders'];

const DESCRIPTION_TEXT =
  'This Blog is intended to create content for people who are interested in Tech and everthing around it. My newsletter is designed to expand on tha same! I will notify you where there is new content so that you never miss out!';

const NO_SPAM_TEXT = 'No Spam. Unsubscribe Anytime. ';

const textFieldSx = {
  '& label.Mui-focused': {
    color: '#ff0a78',
  },
  '& .MuiFormLabel-root': {
    color: '#f2f5f7',
    fontWeight: 'bold',
  },
  '& .MuiInputBase-input': {
    color: '#f2f5f7',
    paddingTop: '24px',
    paddingBottom: '24px',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: '#f2f5f762',
    borderBottomWidth: 3,
  },
  '& .MuiInput-underline:hover:before': {
    borderBottomColor: '#f2f5f7',
    borderBottomWidth: 2,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#ff0a78',
    borderBottomWidth: 3,
  },
};

const buttonStyle = {
  '--button-primary-color': '#ff0a78',
  '--button-primary-color-dark': '#6b0533',
  '--button-primary-color-hover': '#a1084d',
  '--button-primary-color-active': '#a1084d',
};

export type NewsLetterType = 'Staggered' | 'Immersive';
interface NewsLetterProps {
  type: NewsLetterType;
}

const NewsLetter: React.FC<NewsLetterProps> = ({ type }) => {
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(true);
  const [firstNameValid, setFirstNameValid] = useState(true);

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!firstNameValid) {
      setFirstNameValid(true);
    }
    setFirstName(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!emailValid) {
      setEmailValid(true);
    }
    setEmail(event.target.value);
  };

  const onSubmitButtonClicked = () => {
    if (!isValidEmail(email)) {
      setEmailValid(false);
      return;
    }
    if (!isValidName(firstName)) {
      setFirstNameValid(false);
      return;
    }
    addToNewsLetter(firstName, email).then(
      (response: DefaultApiResponseData) => {
        setFirstName('');
        setEmail('');
        if (response.status === STATUS_OK) {
          toast.success(response.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          toast.info(response.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      }
    );
  };

  return (
    <div
      className={`NewsLetterContainer ${
        type === 'Staggered'
          ? 'NewsLetterContainerStaggered'
          : 'NewsLetterContainerImmersive'
      }`}
    >
      <h2 className="NewsLetterHeadingText">{HEADING}</h2>
      <div className="NewsLetterSubHeadingContainer">
        <p className="NewsLetterSubHeadingText">{SUB_HEADING}</p>
        <Typewriter
          speed={100}
          delay={1000}
          text={TYPEWRITER_ARRAY}
          loop={true}
        />
      </div>
      {type === 'Immersive' ? (
        <p className="NewsLetterSubHeadingText">{DESCRIPTION_TEXT}</p>
      ) : null}
      <p className="NewsLetterSubHeadingText">{NO_SPAM_TEXT}</p>

      <div className={type === 'Immersive' ? `NewsLetterFormContainer` : ''}>
        <div className="NewsLetterTextContainer">
          <TextField
            error={!firstNameValid}
            className="NewsLetterText"
            sx={textFieldSx}
            variant="standard"
            label="First Name"
            type="text"
            value={firstName}
            inputProps={{ maxLength: 50 }}
            onChange={handleFirstNameChange}
          />
        </div>
        <div style={{ width: 24 }} />
        <div className="NewsLetterTextContainer">
          <TextField
            error={!emailValid}
            className="NewsLetterText"
            sx={textFieldSx}
            variant="standard"
            label="Email"
            type="email"
            value={email}
            inputProps={{ maxLength: 80 }}
            onChange={handleEmailChange}
          />
        </div>

        <div className="NewsLetterButtonContainer">
          <AwesomeButton
            className="NewsLetterButton"
            type="primary"
            style={buttonStyle}
            onPress={onSubmitButtonClicked}
          >
            Submit
          </AwesomeButton>
        </div>
      </div>
      <div className="NewsLetterButtonContainerMobile">
        <AwesomeButton
          className="NewsLetterButton"
          type="primary"
          style={buttonStyle}
          onPress={onSubmitButtonClicked}
        >
          Submit
        </AwesomeButton>
      </div>
    </div>
  );
};

export default NewsLetter;
