import React from 'react';
import CustomText from '../../components/CustomText/CustomText';
import { AboutMeCardWidget, ImageWithFooterWidget } from '../typesWidget';
import './AboutMeCard.css';

const AboutMeCard: React.FC<AboutMeCardWidget> = () => {
  return (
    <div className="AboutMeCardContainer">
      <div className="AboutMeCardImgContainer">
        <img
          src="https://techitup.s3.ap-south-1.amazonaws.com/about_me/about_me_photo.jpg"
          alt="TechItUp Author Photo"
          className="AboutMeCardImg"
        />
      </div>
      <h1 className="AboutMeCardHeading">Hello there 👋</h1>
      <h1 className="AboutMeCardHeading">I am Suchandrim Sarkar</h1>
      <p className="AboutMeCardDesc">
        First of all, I am glad that you liked my blog enough to come and check
        out the person who built it 🥰
        <br />
        <br /> About me, huh? Well, I love tech and have spent close to a decade
        tinkering around with it, as well as converting some of my ideas into
        final published products.
        <br />
        <br />
        Designing efficient solutions, building great UIs, understanding use
        cases and limitations, and finally implementing them by spending days
        writing code is something that gives me immense joy, and I look forward
        to it every day. I plan to keep doing it as long as I can, while
        learning as much as I can and helping others along the way.
        <br />
        <br />
        Hence, I came up with TechItUp. I love writing and I love building, so a
        tech blog was inevitable. But I wanted to do it my way. Therefore, after
        writing on other platforms like Medium, I decided it was time to build
        something of my own.
      </p>
    </div>
  );
};

export default AboutMeCard;
