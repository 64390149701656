import React from 'react';
import './NotFound.css';
import Lottie from 'lottie-react';
import animation404 from '../../assets/animation_404.json';
import { Helmet } from 'react-helmet';
import { BRAND_IMAGE, TWITTER_USER } from '../../utils/constants';

const OOPS_TEXT1 = "Oops... This shouldn't be happenning.";
const OOPS_TEXT2 = 'Will have a look right away.';
const ERROR_404 = '404. Not Found';

const NotFound = () => {
  return (
    <div className="NotFoundContainer">
      <Helmet>
        <meta charSet="utf-8" />
        <title>404 - Not Found</title>
        <meta name="description" content="404 - Not Found" />
        <meta name="twitter:card" content={BRAND_IMAGE} />
        <meta name="twitter:site" content={TWITTER_USER} />
        <meta name="twitter:creator" content={TWITTER_USER} />
        <meta name="twitter:title" content="404 - Not Found" />
        <meta name="twitter:description" content="404 - Not Found" />
        <meta name="twitter:image" content={BRAND_IMAGE} />
        <meta property="og:title" content="TechItUp - About the Author" />
        <meta property="og:description" content="404 - Not Found" />
        <meta property="og:image" content={BRAND_IMAGE} />
      </Helmet>
      <Lottie
        className="NotFoundLottie"
        animationData={animation404}
        loop={true}
      />
      <h1 className="NotFoundText">{OOPS_TEXT1}</h1>
      <h1 className="NotFoundText">{OOPS_TEXT2}</h1>
      <h2 className="StatusCodeText">{ERROR_404}</h2>
    </div>
  );
};

export default NotFound;
