import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { convertMillisToFullDate } from '../../utils/dateUtils';
import Tag from '../Tag/Tag';

import { BlogSnippetWidget } from '../typesWidget';
import './BlogSnippet.css';

const BlogSnippet: React.FC<BlogSnippetWidget> = ({ data }) => {
  const navigate = useNavigate();

  const getTagWidgets = () => {
    const tagWidgets = data.tags.map(tagWidget => {
      return (
        <div key={tagWidget.id} className="TagWidgetContainer">
          <Tag {...tagWidget} />{' '}
        </div>
      );
    });
    return tagWidgets;
  };

  return (
    <Link className="CardItem" to={'/blog/' + data.urlId}>
      <div className="CoverImageContainer">
        <img
          className="CoverImage"
          src={data.coverImageUrl}
          alt="Cover Image of Blog"
        />
      </div>
      <p className="TimeText">{convertMillisToFullDate(data.time)}</p>
      <h2 className="HeadingText">{data.heading}</h2>
      <p className="DescriptionText">{data.description}</p>
      <div className="TagWidgetsContainer">{getTagWidgets()}</div>
    </Link>
  );
};

export default BlogSnippet;
