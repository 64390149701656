import React from 'react';
import WaveSvg from '../../components/WaveSvg/WaveSvg';
import './ContactScreen.css';
import { AwesomeButtonSocial } from 'react-awesome-button';
import { Helmet } from 'react-helmet';
import { BRAND_IMAGE, TWITTER_USER } from '../../utils/constants';

const TEXT =
  "The best part of running a tech blog is connecting with like-minded folks. So don't be shy – reach out and let's chat tech, swap stories, and collaborate on cool projects 💭";
const TEXT2 =
  "At the end of the day, this blog is all about sharing my passion for technology with others. So if you're as passionate as I am, or even if you just want to learn more, I'd love to hear from you. So what are you waiting for? Let's connect and see where our shared love of tech takes us! 🪄";
const ContactScreen = () => (
  <div className="ContactPageContainer">
    <Helmet>
      <meta charSet="utf-8" />
      <title>TechItUp - Contact</title>
      <meta name="description" content="Contact the Author of TechItUp" />
      <meta name="twitter:card" content={BRAND_IMAGE} />
      <meta name="twitter:site" content={TWITTER_USER} />
      <meta name="twitter:creator" content={TWITTER_USER} />
      <meta name="twitter:title" content="TechItUp - Contact" />
      <meta
        name="twitter:description"
        content="Contact the Author of TechItUp"
      />
      <meta name="twitter:image" content={BRAND_IMAGE} />
      <meta property="og:title" content="TechItUp - About the Author" />
      <meta
        property="og:description"
        content="Contact the Author of TechItUp"
      />
      <meta property="og:image" content={BRAND_IMAGE} />
    </Helmet>
    <div className="WaveContainer">
      <WaveSvg />
    </div>
    <div className="ContactPageInternalContainer">
      <p className="ContactPageHeading">Hello!👋</p>
      <p className="ContactPageText">{TEXT}</p>
      <p className="ContactPageText">{TEXT2}</p>
      <div className="ContactPageButtonsContainer">
        <AwesomeButtonSocial type="github" href="https://github.com/suchoX">
          GitHub
        </AwesomeButtonSocial>
        <AwesomeButtonSocial
          type="linkedin"
          href="https://linkedin.com/in/suchandrim-sarkar-78b6a0b0"
        >
          LinkedIn
        </AwesomeButtonSocial>
        <AwesomeButtonSocial
          type="mail"
          href="mailto:suchandrim.sarkar@gmail.com"
        >
          Email
        </AwesomeButtonSocial>
        <AwesomeButtonSocial
          type="twitter"
          href="https://twitter.com/Suchandrim"
        >
          Twitter
        </AwesomeButtonSocial>
        <AwesomeButtonSocial
          type="instagram"
          href="https://www.instagram.com/sucho94/"
        >
          Instagram
        </AwesomeButtonSocial>
      </div>
    </div>
  </div>
);

export default ContactScreen;
