import React from 'react';
import CustomText from '../../components/CustomText/CustomText';
import { BlogContentTextWidget } from '../typesWidget';
import './BlogContentText.css';

const BlogContentText: React.FC<BlogContentTextWidget> = ({ data }) => {
  return (
    <div className="BlogContentTextContainer">
      <div className="BlogContentTextDiv">
        <CustomText text={data.text} />
      </div>
    </div>
  );
};

export default BlogContentText;
