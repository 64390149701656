import React from 'react';
import TextHeading from '../../components/TextHeading/TextHeading';
import BlogSnippetHighlight from '../BlogSnippetHighlight/BlogSnippetHighlight';
import DividerVertical from '../DividerVertical/DividerVertical';
import { BlogSnippetHighlightsStaggeredWidget } from '../typesWidget';
import './BlogSnippetHighlightsStaggered.css';
import NewsLetter from '../../components/NewsLetter/NewsLetter';

const BlogSnippetHighlightsStaggered: React.FC<
  BlogSnippetHighlightsStaggeredWidget
> = ({ data }) => {
  return (
    <div>
      <TextHeading headingText={'Highlights'} />
      <div className="HighlightsStaggeredContainer">
        <div className="HighlightWithFocusContainer">
          <div className="HighlightFocus">
            <BlogSnippetHighlight {...data[0]} />
          </div>
          <div className="HighlightFocusOthersContainer">
            <div className="HighlightFocusOthers">
              <BlogSnippetHighlight {...data[1]} />
            </div>
            <div className="HighlightFocusOthersDivider">
              <DividerVertical />
            </div>
            <div className="HighlightFocusOthers">
              <BlogSnippetHighlight {...data[2]} />
            </div>
          </div>
          <div className="HighlightFocusOthersContainerRightOptional">
            <div className="HighlightFocusOthers">
              <BlogSnippetHighlight {...data[3]} />
            </div>
            <div className="HighlightFocusOthersDivider">
              <DividerVertical />
            </div>
            <div className="HighlightFocusOthers">
              <BlogSnippetHighlight {...data[4]} />
            </div>
          </div>
        </div>
        <div className="HighlightRightOthersContainer">
          <div className="HighlightRightOthers">
            <BlogSnippetHighlight {...data[3]} />
          </div>
          <div className="HighlightRightOthers">
            <BlogSnippetHighlight {...data[4]} />
          </div>
          <div className="HighlightRightOthers">
            <NewsLetter type={'Staggered'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSnippetHighlightsStaggered;
