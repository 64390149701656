import React from 'react';
import './WaveSvg.css';

const WaveSvg = () => {
  return (
    <div>
      <div className="TopMargin"></div>
      <svg width="100vw" viewBox="0 0 500 250" preserveAspectRatio="none">
        <path
          d="M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z"
          style={{ stroke: 'none', fill: '#1C252E' }}
        ></path>
      </svg>
    </div>
  );
};

export default WaveSvg;
