import axios, { AxiosError } from 'axios';
import {
  AboutMeScreenData,
  BlogListScreenData,
  BlogScreenData,
  HomeScreenData,
  PrivacyScreenData,
} from '../widgets/typesScreen';
import {
  ADD_TO_NEWSLETTER,
  DEFAULT_ABOUT_ME_SCREEN_URL,
  DEFAULT_BLOG_LIST_SCREEN_URL,
  DEFAULT_BLOG_SCREEN_URL,
  DEFAULT_HOME_SCREEN_URL,
  PAGE_HEADER_URL,
  STATUS_INTERNAL_SERVER_ERROR,
} from './constants';
import { PageHeaderData } from '../components/PageHeader/PageHeader';
import { DefaultApiResponseData } from '../models/DefaultApiResponseData';
import { getBaseUrl } from './envUtils';

const UNHANDLED_ERROR = "There has been an error that we didn't Handle!";

function isAxiosError<ResponseType>(
  error: unknown
): error is AxiosError<ResponseType> {
  return axios.isAxiosError(error);
}

export const getDefaultHomeScreenData =
  async (): Promise<HomeScreenData | null> => {
    try {
      const response = await axios.get(getBaseUrl() + DEFAULT_HOME_SCREEN_URL, {
        responseType: 'json',
      });

      if (response.status === 200) {
        const homeScreenData: HomeScreenData = {
          components: response.data.components,
        };
        return homeScreenData;
      } else return null;
    } catch (error) {
      return null;
    }
  };

export const getDefaultBlogScreenData = async (
  urlId: string
): Promise<BlogScreenData | null> => {
  try {
    const response = await axios.get(
      getBaseUrl() + DEFAULT_BLOG_SCREEN_URL + urlId,
      {
        responseType: 'json',
      }
    );

    if (response.status === 200) {
      const blogScreenData: BlogScreenData = {
        helmetData: response.data.helmetData,
        components: response.data.components,
      };
      return blogScreenData;
    } else return null;
  } catch (error) {
    return null;
  }
};

export const getDefaultBlogListScreenData =
  async (): Promise<BlogListScreenData | null> => {
    try {
      const response = await axios.get(
        getBaseUrl() + DEFAULT_BLOG_LIST_SCREEN_URL,
        {
          responseType: 'json',
        }
      );

      if (response.status === 200) {
        const blogScreenData: BlogListScreenData = {
          components: response.data.components,
        };
        return blogScreenData;
      } else return null;
    } catch (error) {
      return null;
    }
  };

export const getDefaultAboutMeScreenData =
  async (): Promise<AboutMeScreenData | null> => {
    try {
      const response = await axios.get(
        getBaseUrl() + DEFAULT_ABOUT_ME_SCREEN_URL,
        {
          responseType: 'json',
        }
      );

      if (response.status === 200) {
        const blogScreenData: AboutMeScreenData = {
          components: response.data.components,
        };
        return blogScreenData;
      } else return null;
    } catch (error) {
      return null;
    }
  };

export const getPageHeaderData = async (): Promise<PageHeaderData | null> => {
  try {
    const response = await axios.get(getBaseUrl() + PAGE_HEADER_URL, {
      responseType: 'json',
    });
    if (response.status === 200) {
      const homeHeaderWidget: PageHeaderData = {
        leftContent: response.data.leftContent,
        centerContent: response.data.centerContent,
      };
      return homeHeaderWidget;
    } else return null;
  } catch (error) {
    return null;
  }
};

export const addToNewsLetter = async (
  firstName: string,
  email: string
): Promise<DefaultApiResponseData> => {
  try {
    const response = await axios.post(getBaseUrl() + ADD_TO_NEWSLETTER, {
      firstName: firstName,
      email: email,
    });
    const defaultApiResponseData: DefaultApiResponseData = {
      message: response.data.message,
      status: response.data.status,
    };
    return defaultApiResponseData;
  } catch (error) {
    if (isAxiosError<DefaultApiResponseData>(error)) {
      const defaultApiResponseData: DefaultApiResponseData = {
        message: error.response?.data.message || UNHANDLED_ERROR,
        status: error.response?.data.status || STATUS_INTERNAL_SERVER_ERROR,
      };
      return defaultApiResponseData;
    } else {
      const defaultApiResponseData: DefaultApiResponseData = {
        message: UNHANDLED_ERROR,
        status: STATUS_INTERNAL_SERVER_ERROR,
      };
      return defaultApiResponseData;
    }
  }
};
