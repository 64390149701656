import React, { useCallback, useEffect, useState } from 'react';
import WaveSvg from '../../components/WaveSvg/WaveSvg';
import { getDefaultBlogListScreenData } from '../../utils/api';
import { getWidgetForType } from '../../widgets/renderer';
import { BlogListScreenData } from '../../widgets/typesScreen';
import './BlogListScreen.css';
import NotFound from '../../components/404/NotFound';
import { Helmet } from 'react-helmet';
import { BRAND_IMAGE, TWITTER_USER } from '../../utils/constants';

const BlogListScreen = () => {
  const [screenData, setScreenData] = useState<BlogListScreenData>({
    components: [],
  });
  const [pageError, setPageError] = useState<Boolean>(false);

  const fetchBlogListScreenData = useCallback(() => {
    getDefaultBlogListScreenData().then((data: BlogListScreenData | null) => {
      if (data) {
        const blogListScreenData: BlogListScreenData = {
          components: data.components,
        };
        setScreenData(blogListScreenData);
        setPageError(false);
      } else {
        setScreenData({
          components: [],
        });
        setPageError(true);
      }
    });
  }, []);

  useEffect(() => {
    fetchBlogListScreenData();
  }, [fetchBlogListScreenData]);

  const renderHelmet = () => {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>TechItUp - Blog List</title>
        <meta
          name="description"
          content="Lists all the Blogs in TechItUp for easy access"
        />
        <meta name="twitter:card" content={BRAND_IMAGE} />
        <meta name="twitter:site" content={TWITTER_USER} />
        <meta name="twitter:creator" content={TWITTER_USER} />
        <meta name="twitter:title" content="TechItUp - Blog List" />
        <meta
          name="twitter:description"
          content="Lists all the Blogs in TechItUp for easy access"
        />
        <meta name="twitter:image" content={BRAND_IMAGE} />
        <meta property="og:title" content="TechItUp - Blog List" />
        <meta
          property="og:description"
          content="Lists all the Blogs in TechItUp for easy access"
        />
        <meta property="og:image" content={BRAND_IMAGE} />
      </Helmet>
    );
  };

  const renderscreenData = () => {
    const widgets = screenData.components.map(widget => {
      return <div key={widget.id}>{getWidgetForType(widget)}</div>;
    });
    return widgets;
  };

  return (
    <div className="BlogListPageContainer">
      <div className="WaveContainer">
        <WaveSvg />
      </div>
      {pageError ? (
        <NotFound />
      ) : (
        <div>
          {renderHelmet()}
          <div className="BlogListPageContentContainer">
            {renderscreenData()}
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogListScreen;
