import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import './index.css';

import './index.css';
import reportWebVitals from './reportWebVitals';
import AboutMe from './screens/aboutMe/AboutMeScreen';
import BlogScreen from './screens/blog/BlogScreen';
import BlogListScreen from './screens/blogList/BlogListScreen';
import Home from './screens/home/Home';
import PrivacyScreen from './screens/privacypolicy/PrivacyScreen';
import PageHeader from './components/PageHeader/PageHeader';
import PageFooter from './components/PageFooter/PageFooter';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContactScreen from './screens/contact/ContactScreen';
import NotFoundScreen from './screens/notFound/NotFoundScreen';
import * as Sentry from '@sentry/react';
import { SENTRY_DSN } from './utils/constants';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-V91W5R3WFD');
Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  tracesSampleRate: 0.1,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Router>
    <div className="page-container">
      <ScrollToTop>
        <PageHeader />
        <div className="page-content">
          <SentryRoutes>
            <Route path="/" element={<Home />} />
            <Route path="/blog/:urlId" element={<BlogScreen />} />
            <Route path="/blogList" element={<BlogListScreen />} />
            <Route path="/aboutMe" element={<AboutMe />} />
            <Route path="/privacy" element={<PrivacyScreen />} />
            <Route path="/contact" element={<ContactScreen />} />
            <Route path="*" element={<NotFoundScreen />} />
          </SentryRoutes>
        </div>
        <PageFooter />
        <ToastContainer toastStyle={{ fontSize: 16 }} />
      </ScrollToTop>
    </div>
  </Router>
);

const SendAnalytics = () => {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
  });
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
