import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { convertMillisToFullDate } from '../../utils/dateUtils';
import { BlogSnippetHighlightWidget } from '../typesWidget';
import './BlogSnippetHighlight.css';

const BlogSnippetHighlight: React.FC<BlogSnippetHighlightWidget> = ({
  data,
}) => {
  return (
    <Link to={'/blog/' + data.urlId} className="HighlightItem">
      <div className="CoverImageContainer">
        <img
          className="HighlightCoverImage"
          src={data.coverImageUrl}
          alt="Cover Image of Blog"
        />
      </div>
      <h2 className="HighlightHeadingText">{data.heading}</h2>
      <p className="HighlightDescriptionText">{data.description}</p>
      <p className="HighlightTimeText">{convertMillisToFullDate(data.time)}</p>
    </Link>
  );
};

export default BlogSnippetHighlight;
