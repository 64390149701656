export const isValidEmail = (email: string): boolean => {
  // Regular expression for validating email addresses
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isValidName = (name: string): boolean => {
  // Regular expression for a valid name
  const nameRegex = /^[a-zA-Z\s]+$/;

  return nameRegex.test(name);
};
