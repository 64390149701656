import React from 'react';
import './NotFoundScreen.css';
import NotFound from '../../components/404/NotFound';

const NotFoundScreen = () => {
  return (
    <div className="NotFoundPageContainer">
      <NotFound />
    </div>
  );
};

export default NotFoundScreen;
