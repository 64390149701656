import { format } from 'fecha';

export const convertMillisToFullDateandTime = (time: number) => {
  let date = new Date(time);
  return format(date, 'dddd, MMMM Do, YYYY, hh:mm A');
};

export const convertMillisToFullDate = (time: number) => {
  let date = new Date(time);
  return format(date, 'MMMM Do, YYYY');
};
