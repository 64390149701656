import React from 'react';
import CustomText from '../../components/CustomText/CustomText';
import { BlogContentListItemWidget } from '../typesWidget';
import './BlogContentListItem.css';

const BlogContentListItem: React.FC<BlogContentListItemWidget> = ({ data }) => {
  return (
    <div className="BlogContentListItemContainer">
      <ul className="BlogContentULStyle">
        <li>
          <div className="BlogContentListItemDiv">
            <CustomText text={data.text} />
          </div>
        </li>
      </ul>
    </div>
  );
};

export default BlogContentListItem;
