import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDefaultBlogScreenData } from '../../utils/api';
import { getWidgetForType } from '../../widgets/renderer';
import { BlogScreenData, HomeScreenData } from '../../widgets/typesScreen';
import './BlogScreen.css';
import NewsLetter from '../../components/NewsLetter/NewsLetter';
import NotFound from '../../components/404/NotFound';
import { Helmet } from 'react-helmet';
import { TWITTER_USER } from '../../utils/constants';

const BlogScreen = () => {
  const { urlId } = useParams();

  const [screenData, setScreenData] = useState<BlogScreenData>({
    helmetData: {
      title: '',
      description: '',
      imageUrl: '',
    },
    components: [],
  });
  const [pageLoaded, setPageLoaded] = useState<Boolean>(false);
  const [pageError, setPageError] = useState<Boolean>(false);

  const fetchBlogScreenData = useCallback(() => {
    if (urlId) {
      getDefaultBlogScreenData(urlId).then((data: BlogScreenData | null) => {
        if (data) {
          const blogScreenData: BlogScreenData = {
            helmetData: data.helmetData,
            components: data.components,
          };
          setScreenData(blogScreenData);
          setPageLoaded(true);
          setPageError(false);
        } else {
          setScreenData({
            helmetData: {
              title: '',
              description: '',
              imageUrl: '',
            },
            components: [],
          });
          setPageError(true);
        }
      });
    }
  }, []);

  const renderHelmet = () => {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{screenData.helmetData.title}</title>
        <meta name="description" content={screenData.helmetData.description} />
        <meta name="twitter:card" content={screenData.helmetData.imageUrl} />
        <meta name="twitter:site" content={TWITTER_USER} />
        <meta name="twitter:creator" content={TWITTER_USER} />
        <meta name="twitter:title" content={screenData.helmetData.title} />
        <meta
          name="twitter:description"
          content={screenData.helmetData.description}
        />
        <meta name="twitter:image" content={screenData.helmetData.imageUrl} />
        <meta property="og:title" content={screenData.helmetData.title} />
        <meta
          property="og:description"
          content={screenData.helmetData.description}
        />
        <meta property="og:image" content={screenData.helmetData.imageUrl} />
      </Helmet>
    );
  };

  useEffect(() => {
    fetchBlogScreenData();
  }, [fetchBlogScreenData]);

  const renderscreenData = () => {
    const widgets = screenData.components.map(widget => {
      return <div key={widget.id}>{getWidgetForType(widget)}</div>;
    });
    return widgets;
  };

  return (
    <div className="BlogPageContainer">
      {pageError ? (
        <NotFound />
      ) : (
        <div>
          {renderHelmet()}
          <article>
            <div className="BlogPageContentContainer">{renderscreenData()}</div>
          </article>
          {pageLoaded && (
            <div className="BlogPageNewsLetterContainer">
              <NewsLetter type={'Immersive'} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BlogScreen;
