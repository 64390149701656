import React from 'react';
import { ImageWithFooterWidget } from '../typesWidget';
import './ImageWithFooter.css';

const ImageWithFooter: React.FC<ImageWithFooterWidget> = ({ data }) => {
  return (
    <div className="ImageWithFooterContainer">
      <img className="ImageWithFooterImage" src={data.url} alt={data.text} />
      <p className="ImageWithFooterText">{data.text}</p>
    </div>
  );
};

export default ImageWithFooter;
