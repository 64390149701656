import React, { useCallback, useEffect, useState } from 'react';
import HamburgerIcon from '../../assets/hamburger_icon.png';
import { Drawer } from '@mui/material';
import { Link } from 'react-router-dom';
import './PageHeader.css';
import { getPageHeaderData } from '../../utils/api';
import { TextUrlData, ImageData } from '../../widgets/typesData';

interface LeftContentData {
  image: ImageData;
}
interface CenterContentData {
  content: TextUrlData[];
}

export interface PageHeaderData {
  leftContent: LeftContentData;
  centerContent: CenterContentData;
}

const PageHeader = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [pageHeaderData, setPageHeaderData] = useState<PageHeaderData | null>();
  const [pageError, setPageError] = useState<Boolean>(false);

  const fetchHeaderWidget = useCallback(() => {
    getPageHeaderData().then((data: PageHeaderData | null) => {
      if (data) {
        setPageHeaderData(data);
        setPageError(false);
      } else {
        setPageHeaderData(null);
        setPageError(true);
      }
    });
  }, []);

  useEffect(() => {
    fetchHeaderWidget();
  }, [fetchHeaderWidget]);

  const toggleDrawer = (fromDrawer: boolean) => {
    if (fromDrawer) setDrawerOpen(!drawerOpen);
  };

  const renderLeftContent = () => {
    if (pageHeaderData)
      return (
        <Link className="PageLeftContainer" to={'/'}>
          <img
            className="PageHeaderLeftLogo"
            src={pageHeaderData.leftContent.image.url}
            alt="TechItUp Logo"
          />
        </Link>
      );
  };

  const renderCenterContent = (drawer = false) => {
    if (pageHeaderData) {
      const className = drawer ? 'PageDrawerItem' : 'PageHeaderCenterText';
      const centerComponents = pageHeaderData.centerContent.content.map(
        textUrlData => {
          return (
            <Link
              key={textUrlData.text}
              className="Link"
              to={textUrlData.click.url}
            >
              <div className={className} onClick={() => toggleDrawer(drawer)}>
                {textUrlData.text}
              </div>
            </Link>
          );
        }
      );
      if (drawer)
        return <div className="PageDrawerContainer">{centerComponents}</div>;
      return <div className="PageCenterContaier">{centerComponents}</div>;
    }
  };

  const renderRightContent = () => {
    return (
      <div className="PageRightContainer">
        <div className="PageHeaderHamburgerIconContainer">
          <img
            src={HamburgerIcon}
            className="PageHeaderHamburgerIcon"
            alt="Drawer Hamburger Icon"
            onClick={() => toggleDrawer(true)}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="PageHeaderContainer">
      <div className="PageHeaderContent">
        {renderLeftContent()}
        {renderCenterContent()}
        {renderRightContent()}
      </div>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer}
        PaperProps={{
          sx: {
            backgroundColor: '#1c252e',
          },
        }}
      >
        {renderCenterContent(true)}
      </Drawer>
    </div>
  );
};

export default PageHeader;
