import React from 'react';
import { BlogContentHeadingWidget } from '../typesWidget';
import './BlogContentHeading.css';

const BlogContentHeading: React.FC<BlogContentHeadingWidget> = ({ data }) => {
  return (
    <div className="BlogContentHeadingContainer">
      <h2 className="BlogContentHeadingText">{data.heading}</h2>
    </div>
  );
};

export default BlogContentHeading;
