import React from 'react';
import TextHeading from '../../components/TextHeading/TextHeading';
import BlogSnippet from '../BlogSnippet/BlogSnippet';
import { BlogSnippetGridWidget } from '../typesWidget';
import './BlogSnippetGrid.css';

const BlogSnippetGrid: React.FC<BlogSnippetGridWidget> = blogSnippetWidget => {
  const renderGrid = () => {
    const blogSnippetItems = blogSnippetWidget.data.map(blogSnippedWidget => {
      return <BlogSnippet key={blogSnippedWidget.id} {...blogSnippedWidget} />;
    });
    return <div className="GridContainer">{blogSnippetItems}</div>;
  };

  return (
    <div>
      <TextHeading headingText={'Latest'} />
      <div className="BlogSnippetGridContainer">{renderGrid()}</div>
    </div>
  );
};

export default BlogSnippetGrid;
