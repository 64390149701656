import React from 'react';
import { TagWidget } from '../typesWidget';
import './Tag.css';

const Tag: React.FC<TagWidget> = ({ data }) => {
  return (
    <div className="TagContainer">
      <a
        href="#"
        className="TagName"
        style={{ color: data.textColor, fontWeight: 600 }}
      >
        {'#' + data.name}
      </a>
    </div>
  );
};

export default Tag;
