import React from 'react';
import CustomText from '../../components/CustomText/CustomText';
import {
  BlogContentHighlightWidget,
  BlogContentTextWidget,
} from '../typesWidget';
import './BlogContentHighlight.css';

const BlogContentHighlight: React.FC<BlogContentHighlightWidget> = ({
  data,
}) => {
  return (
    <div className="BlogContentHighlightContainer">
      <div className="BlogContentHighlightDiv">
        <div className="BlogContentBubbleContainer">
          <CustomText text={data.text} />
        </div>
      </div>
    </div>
  );
};

export default BlogContentHighlight;
