import React from 'react';
import { BlogPageDescriptionWidget } from '../typesWidget';
import './BlogPageDescription.css';

const BlogPageDescription: React.FC<BlogPageDescriptionWidget> = ({ data }) => {
  return (
    <div className="BlogPageDescriptionContainer">
      <p className="BlogPageDescriptionText">{data.description}</p>
    </div>
  );
};

export default BlogPageDescription;
