import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { convertMillisToFullDate } from '../../utils/dateUtils';
import Tag from '../Tag/Tag';

import { BlogSnippetWidget } from '../typesWidget';
import './BlogSnippetImmersive.css';

const BlogSnippetImmersive: React.FC<BlogSnippetWidget> = ({ data }) => {
  const navigate = useNavigate();

  return (
    <Link className="BlogSnippetImmersiveItem" to={'/blog/' + data.urlId}>
      <h2 className="BlogSnippetImmersiveHeadingText">{data.heading}</h2>
      <p className="BlogSnippetImmersiveDescriptionText">{data.description}</p>
      <p className="BlogSnippetImmersiveTimeText">
        {convertMillisToFullDate(data.time)}
      </p>
    </Link>
  );
};

export default BlogSnippetImmersive;
