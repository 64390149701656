import React from 'react';
import './TextHeading.css';

interface TextHeadingProps {
  headingText: string;
}

const TextHeading: React.FC<TextHeadingProps> = ({ headingText }) => {
  return (
    <div className="TextHeadingContainer">
      <h1 className="TextHeadingText">{headingText}</h1>
      <div className="TextHeadingLine"></div>
    </div>
  );
};

export default TextHeading;
