import React from 'react';
import { Link } from 'react-router-dom';
import './PageFooter.css';

const LOGO_URL =
  'https://techitup.s3.ap-south-1.amazonaws.com/logo_transparent_20.png';
const SUBTITLE_TEXT = 'Thanks for Reading!';
const RIGHTS_RESERVED_TEXT = '© 2023-present TechItUp. All Rights Reserved.';
const BUILT_WITH_TEXT = 'Built with 💖 using React and Go';

const PageFooter = () => {
  return (
    <div className="PageFooterContainer">
      <div className="PageFooterContentContainer">
        <div className="PageFooterLeftContainer">
          <img className="PageFooterLogo" src={LOGO_URL} alt="TechItUp Logo" />
          <p className="PageFooterSubtitleText">{SUBTITLE_TEXT}</p>
          <p className="PageFooterBuiltWithText">{BUILT_WITH_TEXT}</p>
          <Link
            to="https://www.flaticon.com/free-icons/app"
            className="PageFooterFlatIconLink"
            title="app icons"
            target="_blank"
          >
            Icons created by Freepik - Flaticon
          </Link>
        </div>
        <div className="PageFooterRightContainer"></div>
        <div className="PageFooterRightContainer">
          <p className="PageFooterLinksHeadingText">Links</p>
          <Link
            to="https://twitter.com/Suchandrim"
            target="_blank"
            className="PageFooterLinksText"
          >
            Twitter
          </Link>
          <Link
            to="mailto:suchandrim.sarkar@gmail.com"
            className="PageFooterLinksText"
          >
            Email
          </Link>
          <Link to={'/privacy'} className="PageFooterLinksText">
            Privacy Policy
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PageFooter;
