import React from 'react';
import { BlogPageHeadingWidget } from '../typesWidget';
import './BlogPageHeading.css';

const BlogPageHeading: React.FC<BlogPageHeadingWidget> = ({ data }) => {
  return (
    <div className="BlogPageHeadingContainer">
      <h1 className="BlogPageHeadingText">{data.heading}</h1>
    </div>
  );
};

export default BlogPageHeading;
