import React from 'react';
import './BlogPageTime.css';
import { BlogPageTimeWidget } from '../typesWidget';
import { convertMillisToFullDate } from '../../utils/dateUtils';

const UPDATED_ON_TEXT = 'Updated on ';
const PUBLISHED_ON_TEXT = 'Published on ';
const BlogPageTime: React.FC<BlogPageTimeWidget> = ({ data }) => {
  const getText = (): string => {
    return data.updated
      ? UPDATED_ON_TEXT + convertMillisToFullDate(data.time)
      : PUBLISHED_ON_TEXT + convertMillisToFullDate(data.time);
  };

  return (
    <div className="BlogPageTimeContainer">
      <p className="BlogPageTimeText">{getText()}</p>
    </div>
  );
};

export default BlogPageTime;
