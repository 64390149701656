import React from 'react';
import AboutMeCard from './AboutMeCard/AboutMeCard';
import BlogContentHeading from './BlogContentHeading/BlogContentHeading';
import BlogContentHighlight from './BlogContentHighlight/BlogContentHighlight';
import BlogContentListItem from './BlogContentListItem/BlogContentListItem';
import BlogContentText from './BlogContentText/BlogContentText';
import BlogPageDescription from './BlogpageDescription/BlogPageDescription';
import BlogPageHeading from './BlogPageHeading/BlogPageHeading';
import BlogPageTime from './BlogPageTime/BlogPageTime';
import BlogSnippet from './BlogSnippet/BlogSnippet';
import BlogSnippetGrid from './BlogSnippetGrid/BlogSnippetGrid';
import BlogSnippetHighlight from './BlogSnippetHighlight/BlogSnippetHighlight';
import BlogSnippetHighlightsStaggered from './BlogSnippetHighlightsStaggered/BlogSnippetHighlightsStaggered';
import BlogSnippetImmersiveList from './BlogSnippetImmersiveList/BlogSnippetImmersiveList';
import CodeBlock from './CodeBlock/CodeBlock';
import ImageWithFooter from './ImageWithFooter/ImageWithFooter';
import Tag from './Tag/Tag';
import {
  BlogContentHeadingWidget,
  BlogContentHighlightWidget,
  BlogContentListItemWidget,
  BlogContentTextWidget,
  BlogPageDescriptionWidget,
  BlogPageHeadingWidget,
  BlogPageTimeWidget,
  BlogSnippetGridWidget,
  BlogSnippetHighlightsStaggeredWidget,
  BlogSnippetHighlightWidget,
  BlogSnippetImmersiveListWidget,
  BlogSnippetWidget,
  CodeBlockWidget,
  GistWidget,
  ImageWithFooterWidget,
  TagWidget,
  WidgetDataTypes,
} from './typesWidget';
import Gist from './Gist/Gist';

export const getWidgetForType = (widget: WidgetDataTypes) => {
  switch (widget.widgetType) {
    case 'TAG':
      return <Tag {...(widget as TagWidget)} />;
    case 'BLOG_SNIPPET':
      return <BlogSnippet {...(widget as BlogSnippetWidget)} />;
    case 'BLOG_SNIPPETS_GRID':
      return <BlogSnippetGrid {...(widget as BlogSnippetGridWidget)} />;
    case 'BLOG_SNIPPET_HIGHLIGHT':
      return (
        <BlogSnippetHighlight {...(widget as BlogSnippetHighlightWidget)} />
      );
    case 'BLOG_SNIPPET_HIGHLIGHTS_STAGGERED':
      return (
        <BlogSnippetHighlightsStaggered
          {...(widget as BlogSnippetHighlightsStaggeredWidget)}
        />
      );
    case 'BLOG_PAGE_HEADING':
      return <BlogPageHeading {...(widget as BlogPageHeadingWidget)} />;
    case 'BLOG_PAGE_DESCRIPTION':
      return <BlogPageDescription {...(widget as BlogPageDescriptionWidget)} />;
    case 'BLOG_PAGE_TIME':
      return <BlogPageTime {...(widget as BlogPageTimeWidget)} />;
    case 'BLOG_CONTENT_HEADING':
      return <BlogContentHeading {...(widget as BlogContentHeadingWidget)} />;
    case 'CODE_BLOCK':
      return <CodeBlock {...(widget as CodeBlockWidget)} />;
    case 'GIST':
      return <Gist {...(widget as GistWidget)} />;
    case 'BLOG_CONTENT_IMAGE':
      return <ImageWithFooter {...(widget as ImageWithFooterWidget)} />;
    case 'BLOG_CONTENT_TEXT':
      return <BlogContentText {...(widget as BlogContentTextWidget)} />;
    case 'BLOG_LIST_ITEM':
      return <BlogContentListItem {...(widget as BlogContentListItemWidget)} />;
    case 'BLOG_CONTENT_HIGHLIGHT':
      return (
        <BlogContentHighlight {...(widget as BlogContentHighlightWidget)} />
      );
    case 'BLOG_SNIPPETS_IMMERSIVE_LIST':
      return (
        <BlogSnippetImmersiveList
          {...(widget as BlogSnippetImmersiveListWidget)}
        />
      );
    case 'ABOUT_ME_CARD':
      return <AboutMeCard id={''} widgetType={'ABOUT_ME_CARD'} />;
  }
};
