export const BASE_URL_DEV = 'http://192.168.1.16:4000';
export const BASE_URL_PROD =
  'https://techitup-middleware-klxlafm4aa-el.a.run.app';

export const DEFAULT_HOME_SCREEN_URL = '/home/default';
export const DEFAULT_BLOG_SCREEN_URL = '/blog/default/';
export const DEFAULT_BLOG_LIST_SCREEN_URL = '/blogList/default';
export const DEFAULT_ABOUT_ME_SCREEN_URL = '/aboutMe';
export const PAGE_HEADER_URL = '/header';
export const ADD_TO_NEWSLETTER = '/newsletter';

export const STATUS_OK = 200;
export const STATUS_BAD_REQUEST = 400;
export const STATUS_INTERNAL_SERVER_ERROR = 500;

export const TYPE_NAVIGATION = 'navigation';

export const TECHITUP_DESC =
  "A Tech Blog where we talk through the ever-evolving world of technology, from the latest breakthroughs in development to the everyday tech that makes our lives easier. Whether you're a seasoned developer or a tech enthusiast, you'll find something to pique your interest here. So come on in and let's geek out together!";
export const BRAND_IMAGE =
  'https://techitup.s3.ap-south-1.amazonaws.com/brand_image.jpg';
export const TWITTER_USER = '@Suchandrim';

export const ABOUT_ME_DESCRIPTION =
  'Hello, Suchandrim Sarkar here. I love tech and have spent close to a decade building and developing tech products. I love writing as well, Hence, I came up with TechItUp';

export const SENTRY_DSN =
  'https://0c30d57551594f63b78027411d54e890@o4504973150978048.ingest.sentry.io/4504977104896000';
