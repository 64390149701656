import React from 'react';
import './Gist.css';
import { GistWidget } from '../typesWidget';

import ReactEmbedGist from 'react-embed-gist';

const Gist: React.FC<GistWidget> = ({ data }) => {
  const splits = data.id.split('/');
  return (
    <div className="GistContainer">
      <ReactEmbedGist
        gist={`${splits[0]}/${splits[1]}`}
        wrapperClass="GistContainer"
      />
    </div>
  );
};

export default Gist;
