import React, { useCallback, useEffect, useState } from 'react';
import WaveSvg from '../../components/WaveSvg/WaveSvg';
import { getDefaultHomeScreenData } from '../../utils/api';
import { getWidgetForType } from '../../widgets/renderer';
import { HomeScreenData } from '../../widgets/typesScreen';
import './Home.css';
import NewsLetter from '../../components/NewsLetter/NewsLetter';
import NotFound from '../../components/404/NotFound';
import Lottie from 'lottie-react';
import developer from '../../assets/developer.json';
import { Helmet } from 'react-helmet';
import {
  BRAND_IMAGE,
  TECHITUP_DESC,
  TWITTER_USER,
} from '../../utils/constants';

const HOME_HELMET = (
  <Helmet>
    <meta charSet="utf-8" />
    <title>TechItUp - Tech Blog for Tech Enthusiasts & Developers</title>
    <meta name="description" content={TECHITUP_DESC} />
    <meta name="twitter:card" content={BRAND_IMAGE} />
    <meta name="twitter:site" content={TWITTER_USER} />
    <meta name="twitter:creator" content={TWITTER_USER} />
    <meta name="twitter:title" content="TechItUp" />
    <meta
      name="twitter:description"
      content="A Tech Blog for Tech Enthusiasts, Developers and Tinkerers"
    />
    <meta name="twitter:image" content={BRAND_IMAGE} />
    <meta
      property="og:title"
      content="TechItUp - A Tech Blog for Tech Enthusiasts, Developers and Tinkerers"
    />
    <meta property="og:description" content={TECHITUP_DESC} />
    <meta property="og:image" content={BRAND_IMAGE} />
  </Helmet>
);

const Home = () => {
  const [screenData, setScreenData] = useState<HomeScreenData>({
    components: [],
  });
  const [pageLoaded, setPageLoaded] = useState<Boolean>(false);
  const [pageError, setPageError] = useState<Boolean>(false);

  const fetchHomeScreenData = useCallback(() => {
    getDefaultHomeScreenData().then((data: HomeScreenData | null) => {
      if (data) {
        const homeScreenData: HomeScreenData = {
          components: data.components,
        };
        setScreenData(homeScreenData);
        setPageLoaded(true);
        setPageError(false);
      } else {
        setScreenData({
          components: [],
        });
        setPageError(true);
      }
    });
  }, []);

  useEffect(() => {
    fetchHomeScreenData();
  }, [fetchHomeScreenData]);

  const renderscreenData = () => {
    const widgets = screenData.components.map(widget => {
      return <div key={widget.id}>{getWidgetForType(widget)}</div>;
    });
    return widgets;
  };

  return (
    <div className="Container">
      <div className="WaveContainer">
        <WaveSvg />
      </div>
      {pageError ? (
        <NotFound />
      ) : (
        <div>
          {HOME_HELMET}
          <Lottie
            className="LottieDeveloper"
            animationData={developer}
            loop={true}
          />
          <div className="ContentConatiner">{renderscreenData()}</div>

          {pageLoaded && (
            <div className="HomeNewsLetterContainer">
              <NewsLetter type={'Immersive'} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Home;
