import React from 'react';
import BlogSnippet from '../BlogSnippet/BlogSnippet';
import BlogSnippetImmersive from '../BlogSnippetImmersive/BlogSnippetImmersive';
import { BlogSnippetImmersiveListWidget } from '../typesWidget';
import './BlogSnippetImmersiveList.css';

const BlogSnippetImmersiveList: React.FC<
  BlogSnippetImmersiveListWidget
> = blogSnippetWidget => {
  const renderGrid = () => {
    const blogSnippetItems = blogSnippetWidget.data.map(
      blogSnippetImmersive => {
        return (
          <BlogSnippetImmersive
            key={blogSnippetImmersive.id}
            {...blogSnippetImmersive}
          />
        );
      }
    );
    return (
      <div className="BlogSnippetImmersiveContainer">{blogSnippetItems}</div>
    );
  };

  return (
    <div>
      <div className="BlogSnippetImmersiveListContainer">{renderGrid()}</div>
    </div>
  );
};

export default BlogSnippetImmersiveList;
