import React from 'react';
import './CodeBlock.css';
import { CodeBlockWidget } from '../typesWidget';
import {
  CopyBlock,
  CodeBlock as CodeBlockPackage,
  dracula,
} from 'react-code-blocks';

const CodeBlock: React.FC<CodeBlockWidget> = ({ data }) => {
  return (
    <div className="CodeBlockContainer">
      <CopyBlock
        text={data.code}
        language={data.language}
        theme={dracula}
        wrapLines
        customStyle={{
          width: '90%',
          paddingTop: '24px',
          paddingBottom: '24px',
          fontSize: '1rem',
        }}
      />
    </div>
  );
};

export default CodeBlock;
