import React, { useCallback, useEffect, useState } from 'react';
import WaveSvg from '../../components/WaveSvg/WaveSvg';
import { getDefaultAboutMeScreenData } from '../../utils/api';
import { getWidgetForType } from '../../widgets/renderer';
import { AboutMeScreenData } from '../../widgets/typesScreen';
import './AboutMeScreen.css';
import NotFound from '../../components/404/NotFound';
import { Helmet } from 'react-helmet';
import {
  ABOUT_ME_DESCRIPTION,
  BRAND_IMAGE,
  TWITTER_USER,
} from '../../utils/constants';

const AboutMe = () => {
  const [screenData, setScreenData] = useState<AboutMeScreenData>({
    components: [],
  });
  const [pageError, setPageError] = useState<Boolean>(false);

  const fetchAboutMeScreenData = useCallback(() => {
    getDefaultAboutMeScreenData().then((data: AboutMeScreenData | null) => {
      if (data) {
        const aboutMeScreenData: AboutMeScreenData = {
          components: data.components,
        };
        setScreenData(aboutMeScreenData);
        setPageError(false);
      } else {
        setScreenData({
          components: [],
        });
        setPageError(true);
      }
    });
  }, []);

  useEffect(() => {
    fetchAboutMeScreenData();
  }, [fetchAboutMeScreenData]);

  const renderHelmet = () => {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>TechItUp - About the Author</title>
        <meta name="description" content={ABOUT_ME_DESCRIPTION} />
        <meta name="twitter:card" content={BRAND_IMAGE} />
        <meta name="twitter:site" content={TWITTER_USER} />
        <meta name="twitter:creator" content={TWITTER_USER} />
        <meta name="twitter:title" content="TechItUp - About the Author" />
        <meta name="twitter:description" content={ABOUT_ME_DESCRIPTION} />
        <meta name="twitter:image" content={BRAND_IMAGE} />
        <meta property="og:title" content="TechItUp - About the Author" />
        <meta property="og:description" content={ABOUT_ME_DESCRIPTION} />
        <meta property="og:image" content={BRAND_IMAGE} />
      </Helmet>
    );
  };

  const renderscreenData = () => {
    const widgets = screenData.components.map(widget => {
      return <div key={widget.id}>{getWidgetForType(widget)}</div>;
    });
    return widgets;
  };

  return (
    <div className="AboutMePageContainer">
      <div className="WaveContainer">
        <WaveSvg />
      </div>
      {pageError ? (
        <NotFound />
      ) : (
        <div>
          {renderHelmet()}
          <div className="AboutMePageContentContainer">
            {renderscreenData()}
          </div>
        </div>
      )}
    </div>
  );
};

export default AboutMe;
